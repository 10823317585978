:root {
  --background-transparent: transparent;
  --link-color: #3490dc;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1e2022 !important;
  color: white;
  font-size: 12px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  color: var(--link-color);
  text-decoration: none;
  background-color: transparent;
}

.default-container {
  width: 85%;
  max-width: 420px;
  text-align: center;
  margin: 42px auto;
}

.button-link {
  font-size: 16px;
  padding: 18px 22px 18px 22px;
  align-items: center;
  text-transform: none;
  background-color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  border-radius: 4px;
  width: "100%";
  display: flex;
  justify-content: center;
}
